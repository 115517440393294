import { FC, HTMLAttributes } from "react";
import joinClassNames from "classnames";
import FocusLock from "react-focus-lock";

import classes from "./styles/classes.module.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
  returnFocus?: ReturnFocus;
}

const Overlay: FC<Props> = ({
  children,
  returnFocus = true,
  className,
  ...props
}) => (
  <FocusLock returnFocus={returnFocus}>
    <div
      tabIndex={-1}
      className={joinClassNames(classes.wrapper, className)}
      {...props}
    >
      {children}
    </div>
  </FocusLock>
);

export default Overlay;
